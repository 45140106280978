<template>
  <main class="main">
    <div class="main__inner">
      <section class="section">
        <h2>Подведомственные учреждения</h2>
        <form @submit.prevent="load(1)" class="filter__form filter-form__pb" autocomplete="off">
          <div class="search">
            <div class="search__input">
              <label for="docs_search" class="search__label">Название учреждения для поиска</label>
              <div class="search__input__wrap">
                <input v-model="form.title.value" id="docs_search" type="text" maxlength="255" />
                <button v-if="form.title.value" type="button" @click="resetSearchInput">
                  <CloseIcon />
                </button>
              </div>
            </div>
            <div class="search__btn">
              <button class="btn-blue btn--small" type="submit" :disabled="loading">
                <LoadingIndicator title="Загрузка" v-if="loading" />
                <template v-else>Найти</template>
              </button>
            </div>
          </div>
        </form>

        <LoadingIndicator v-if="loading" title="Загрузка" />
        <template v-else>
          <p v-if="isFilter" class="result__text">
            По вашему запросу найдено совпадений: {{ this.subdivisions.paginatorInfo ? this.subdivisions.paginatorInfo.total : 0 }}
          </p>
          <template v-if="subdivisions && subdivisions.data && subdivisions.data.length">
            <div class="subdivisions__list">
              <div
                v-for="(item, index) in subdivisions.data"
                :key="index"
                class="subdivisions__item-wrap"
              >
                <a :href="item.url" v-if="item.title" target="_blank" class="subdivisions__item _item__title">
                  {{ item.title }}
                </a>
              </div>

              <PaginationComponent
                v-if="
                  page !== null &&
                  subdivisions.paginatorInfo.total !== null &&
                  subdivisions.paginatorInfo.total > first
                "
                :total="Math.ceil(subdivisions.paginatorInfo.total / first)"
                @change="paginate"
                :page="page"
              />
            </div>
          </template>
        </template>
      </section>
    </div>
  </main>
</template>

<script>
import CloseIcon from '@/components/svg/CloseIcon.vue'
import LoadingIndicator from '@/components/LoadingIndicator.vue'
import PaginationComponent from '@/components/Pagination.vue'
import PAGINATE_SUBDIVISIONS from '@/graphql/subdivisions/Subdivisions.graphql'

export default {
  name: 'DepartmentSubdivisionsPage',
  async asyncData ({ store, apollo }) {
    await apollo.clients.dept.query({
      query: PAGINATE_SUBDIVISIONS,
      variables: {
        first: 25,
        page: 1
      }
    }).then(({ data }) => {
      if (data.subdepartments) {
        store.state.subdivisions = data.subdepartments
      }
      if (data.this_department) {
        store.state.this_department = data.this_department
      }
    })
  },
  data () {
    return {
      page: 1,
      first: 25,
      loading: false,
      filterData: {},
      form: {
        title: {
          value: null,
          message: null
        }
      }
    }
  },
  computed: {
    subdivisions () {
      return this.$store.state.subdivisions
    },
    isFilter () {
      const keys = Object.keys(this.filterData)
      return keys && keys.length
    }
  },
  methods: {
    paginate (e) {
      this.page = e
      this.load()
      window.scrollTo(0, 0)
    },
    /**
     * Фильтр документов
     * @returns {Promise<void>}
     */
    async load (page) {
      if (!this.loading) {
        this.loading = true
        this.filterData = {}
        Object.keys(this.form).forEach((key) => {
          if (this.form[key].value !== null) this.filterData[key] = this.form[key].value
        })
        if (page) this.page = page
        await this.$apollo.provider.clients.dept
          .query({
            query: PAGINATE_SUBDIVISIONS,
            variables: Object.assign({
              ...this.filterData,
              page: this.page,
              first: this.first
            })
          })
          .then(({ data }) => {
            this.loading = false
            if (data.subdepartments) {
              this.$store.commit('subdivisions', data.subdepartments)
            }
            if (data.this_department) {
              this.$store.commit('this_department', data.this_department)
            }
          })
          .catch((err) => {
            this.loading = false
            console.error(err)
          })
      }
    },
    /**
     * Сброс фильтра
     */
    resetFilterForm () {
      Object.keys(this.form).forEach((key) => {
        this.form[key].value = null
      })
      this.paginate(1)
    },
    /**
     * Очищение строки поиска
     */
    resetSearchInput () {
      this.form.title.value = null
      this.paginate(1)
    }
  },
  metaInfo () {
    return this.$seo(
      'common',
      'Официальный сайт Главы города Махачкалы',
      '',
      '',
      'Официальный сайт Главы города Махачкалы',
      '',
      ''
    )
  },
  jsonld () {
    return {
      '@context': 'https://schema.org',
      '@type': 'Organization'
    }
  },
  components: {
    CloseIcon,
    LoadingIndicator,
    PaginationComponent
  }
}
</script>

<style lang="stylus">
.subdivisions {
  &__item-wrap:not(:first-child) {
    border-top 1px solid var(--color_gray_divider)
  }

  &__item {
    padding 16px 0
    +below(380px) {
      padding 12px 0
    }
  }
}
</style>
